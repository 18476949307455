import React from 'react';
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from '../images/logo.png';
import './Header.css';
import { HashLink as Link } from 'react-router-hash-link';

const Header = () => {
	const navRef = useRef();

    const toggleNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
      };

    return (
        <header className="app-header">
            <a href=''><img src={logo} className='app-header-logo'></img></a>
            <div className='app-header-contact-text'>
                <h1>519-400-3838</h1>
                <h2>FREE ESTIMATES</h2>
            </div>

            <div className='app-header-nav'>
                <nav ref={navRef}>
                    <ul>
                        {/* <li onClick={toggleNavbar}><a href="">Home</a></li>
                        <li onClick={toggleNavbar}><a href="#about">About</a></li>
                        <li onClick={toggleNavbar}><a href="#contact">Contact</a></li> */}
                        <li onClick={toggleNavbar}><Link to="#home">Home</Link></li>
                        <li onClick={toggleNavbar}><Link to="#about">About</Link></li>
                        <li onClick={toggleNavbar}><Link to="#contact">Contact</Link></li>
                    </ul>
                    <button
                        className="nav-btn nav-close-btn"
                        onClick={toggleNavbar}>
                        <FaTimes />
				    </button>
                    
                </nav>
                <button
                    className="nav-btn"
                    onClick={toggleNavbar}>
                    <FaBars />
			    </button>
            </div>

        </header>
    );
};

export default Header;