import map from './images/map.jpeg';
import './App.css';
import Header from './components/Header';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import { BrowserRouter as Router, Route } from 'react-router-dom';


function App() {
  return (
    <Router>
    <div className="App" id='home'>
      <Header />
      <div className="App-background-image-1">
        <h2 className='image-h2'>Servicing all of Southern Ontario and the GTA</h2>
        <h1 className='image-h1' id='about'>ELECTRICAL CONTRACTOR</h1>
      </div>

      <div className="App-content" >
        <h1 className='content-h1'>
          Full Service Electrical Contractor in Southern Ontario 
        </h1>
        <p>
        Welcome to our electrical services business, proudly serving Southern Ontario. <br></br> <br></br>With a passion for excellence and a commitment to safety, we specialize in lighting solutions, home renovations, and more. Our experienced team ensures top-quality workmanship and reliable service for all your electrical needs. Whether you’re upgrading your home or enhancing your lighting, trust us to deliver exceptional results every time.
        <br></br> <br></br>With 24-hour emergency service available, you can rely on us whenever you require assistance. Our commitment to transparency includes providing free estimates for all projects. Additionally, we stand behind our work with a money-back guarantee, ensuring your satisfaction with our professional and reliable service.
        <br></br> <br></br>ESA (Electrical Safety Authority) with license number 7007048.
        </p>
      </div>

      <div className="App-background-image-2">
        <h1 className='image2-h1'>Services</h1>
        <div className='services-container'>
          <ul className='bullet-points'>
            <div className="column">
              <li>24-hour Emergency Service</li>
              <li>Wiring and Installation</li>
              <li>Smart Home Installation</li>
              <li>Ceiling Fan Repair and Installation</li>
              <li>Electrical Switches and Outlets</li>
            </div>
            <div className="column">
              <li>Circuit Breakers</li>
              <li>Generators Repair & Installations</li>
              <li>Electrical Inspections & Code Compliance</li>
              <li>Electrical Panels</li>
              <li>Hot Tub Installation</li>
            </div>
            <div className="column">
              <li>EV Charger Installations</li>
              <li>Basement Renovations</li>
              <li>Outdoor Lighting Installation</li>
              <li>Indoor Lighting Installation</li>
              <li>Potlights</li>
            </div>
          </ul>
        </div>

      </div>
      <h1 className='content-h1-map'>Areas we serve</h1>
      <div className="App-map-image" >
        <img src={map} className='map-image'></img>


      </div>

      {/* <h1 className='image3-h1'>Areas we serve</h1>
      <div className="App-background-image-3">
      </div> */}


      <div className="App-content" >
        <h1 className='content-h1'>Client Testimonials</h1>
        <h1 id='contact'></h1>
        <Testimonials />

      </div>

      <div className="App-contact-form">
        {/* <h1 className='content-h1'>Contact Form</h1> */}
        <Contact />

      </div>

    </div>
    </Router>
  );
}

export default App;
