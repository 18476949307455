export const reviews = [
    {
      id: 1,
      name: 'Ashutosh Sharma',
      text: 'Mr. Joshi is a very experienced and a professional electrician and we will always consult him for our needs. He installed all the pot lights in our home and found solutions for other complicated scenarios we needed resolved. Lucky to have such an expert like him in Guelph!',
    },
    {
      id: 2,
      name: 'Dayna Tennant',
      text: 'Arun did a fantastic job solving our electrical problems.   Nice guy, reasonably priced, and even came on a Sunday. Will call again when needed!',
    },
    {
      id: 3,
      name: 'G. Marcotte',
      text: 'He confirmed the appointment, arrived on time, and the job was done in ten minutes!  Superb.  Thanks.',
    },
    {
      id: 4,
      name: 'Joe Lebeau',
      text: 'I would recommend this guy to everybody',
    },
  ];